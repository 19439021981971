
import { defineComponent, onMounted, ref, Ref } from 'vue'
import { JobBankRouteNames } from '@/router/route-names'
import { JobOffersAPI } from '@/api/job-offers.api'
import { useRoute } from 'vue-router'
import { IJobOfferResponse } from '@/models/jobBank/interfaces/jobOffer'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  setup () {
    const route = useRoute()
    const { jobOfferId } = route.params
    const content: Ref <IJobOfferResponse> = ref({
      name: '',
      companyId: '',
      modalityId: '',
      quantityEmployees: 0,
      areaId: '',
      typeCompanyId: '',
      hierarchyId: '',
      link: '',
      cityId: '',
      _id: '',
      __v: 0,
      publishDate: null,
      endDate: null,
      updatedAt: null,
      createdAt: null,
      modality: [{ _id: '', value: '', type: '' }],
      area: [{ _id: '', value: '', type: '' }],
      company: [{
        _id: '',
        name: '',
        __v: 0,
        sector: '',
        logo: '',
        description: '',
        updatedAt: null,
        createdAt: null
      }],
      city: [{ _id: '', name: '', __v: 0, countryId: '', countryName: '' }],
      hierarchy: [{ _id: '', value: '', type: '' }]
    })

    const formatModality = ref('')
    const formatCompany = ref({})
    const formatHierarchy = ref('')
    const formatCity = ref({})
    const formatArea = ref()

    const getJobOffer = async () => {
      content.value = await JobOffersAPI.findJobOffer(jobOfferId as string)
      formatFormOfferData(content.value)
    }

    const formatFormOfferData = (content: any) => {
      formatModality.value = content.modality[0].value
      formatCompany.value = content.company[0]
      formatHierarchy.value = content.hierarchy[0].value
      formatCity.value = content.city[0]
      formatArea.value = content.area[0].value
    }

    const handleStatusChange = () => {
      openModal()
    }

    const displayModal = ref(false)
    const openModal = () => {
      displayModal.value = true
    }

    const closeModal = () => {
      displayModal.value = false
    }

    const toast = useToast()

    const unPublish = () => {
      content.value.publishDate = null
      content.value.endDate = null
      JobOffersAPI.updateJobOffer(content.value, content.value._id)
      closeModal()
      toast.add({ severity: 'success', detail: '¡Listo! Se dejó de publicar la oferta laboral.', life: 3500 })
    }

    onMounted(async () => {
      await getJobOffer()
    })
    return {
      JobBankRouteNames,
      content,
      formatModality,
      formatCompany,
      formatHierarchy,
      formatCity,
      formatArea,
      handleStatusChange,
      unPublish,
      openModal,
      closeModal,
      displayModal
    }
  }
})
